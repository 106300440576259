.header {
  max-width: 250px;
  background-color: #f1f4fa;
  transition: all 0.6s ease;
}

.header-logo {
  min-height: 140px;
}

.menu-backdrop-show {
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
}
.menu-backdrop-hide {
  z-index: 0;
}

.menu-backdrop-hide .backdrop {
  z-index: -1;
}

.menu_con .backdrop {
  position: absolute;
  width: 100%;
  height: 100vh;
  /* z-index: 1; */
}

.header,
.menu_con,
.backdrop {
  transition: all 0.8s ease-in-out;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .header {
    max-width: 200px;
    width: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    max-width: 300px;
    width: 300px;
  }
  .header-hide {
    left: -300px;
  }
  .header-show {
    left: 0px;
  }
  .menu_con {
    width: 100%;
  }
}
