.content-con {
  width: calc(100% - 250px) !important;
  margin-left: 250px;
  z-index: 0;
}
.content-con-wr {
  width: calc(100% - 120px);
  margin-left: 120px;
  z-index: 0;
}
.overflow-overlay-y {
  overflow-y: overlay !important;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .content-con {
    margin-left: 200px;
    width: calc(100% - 200px) !important;
  }
}
@media only screen and (max-width: 768px) {
  .content-con {
    width: 100% !important;
    margin-left: 0px;
  }
  .content-con-wr {
    width: 100%;
    margin-left: 00px;
  }
}
